@import '../../assets/CollorPallet.module.scss';

.devicesBtn {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

//dropzone area
.dropzoneArea {
    margin: 5px 15px 15px 15px;
    max-height: 150px;
    overflow-y: auto;
    width: 95% !important;
}

.showImagesBtn {
    color: white !important;
    margin: 15px !important
}

.deleteImagesBtn {
    position: absolute !important;
    right: -20px !important;
    top: -20px !important;
}

.displayImagesContainer {
    max-height: 680px !important;
    display: flex !important;
    overflow-x: auto !important;
}

.displayImages {
    height: 200px !important;
    margin: 15px 15px !important;
    position: relative !important;
}

//device
.deviceContainer {
    margin: 15px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
}

.deviceSaveBtn {
    color: $white;
    background-color: $dashboard-color !important;
    margin-top: 5px !important;
    font-size: 14px !important;
}

//
.deviceType {
    margin: -15px 15px 15px 15px !important;
}

.textField {
    margin: 10px !important;
    width: 94% !important;
}

.searchBtn {
    margin: 17px 32px 20px 10px !important;
    color: white !important;
    font-size: 14px !important
}