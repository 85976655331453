.headerBox {
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 10,
}

.headerToolbar {
    display: flex;
    justify-content: space-between
}

.headerToolbarDiv {
    display: flex !important;
    flex-direction: row !important;
}

.headerBreadcrumbs {
    font-size: 18px !important;
    font-weight: normal !important;
    margin-left: 25px !important;
    margin-top: 10px !important;
    color: black !important;
}

.menuSettings {
    padding-right: 10px
}