.filters {
  display: flex !important;
  padding: 10px !important;
  flex-direction: column !important;
  transition-timing-function: ease-in-out !important;
  transition: 0.5s !important;
  /* Move into place */
  transform: translateY(0) !important;
}

.filtersHide {
  display: none;
  transition-timing-function: ease-in-out !important;
  transition: 0.95s !important;
  /* Move into place */
  transform: translateY(0) !important;
}

.container {
  justify-content: space-between;
  margin-top: 5px;
  max-width: 330px;

  @media (max-width: 767px) {
    margin-top: -20px;
  }
}

.containerHide {
  justify-content: space-between;
  margin-top: 5px;
  min-width: 0px;
}

.reportsSlider {
  margin: 0px;
  padding: 0px;
}

.mainTable {
  width: 100%;
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
}