@import '../../assets/CollorPallet.module.scss';

.collapse {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    text-align: center !important;
    cursor: pointer;
}

.arrowIcon {
    font-size: 25px !important;
    margin: 10 !important;
}

.checkIconDiv {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.checkIcon {
    margin-right: 10px;
    color: $primary-color;
}

.filters {
    text-align: end;
    margin-bottom: 10px;
}

.statusOrder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: -24px;
    position: fixed;
    width: calc(100% - 240px);
    z-index: 2;
}

.applyFilters {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 10px 0px 20px 0px
}

.date {
    width: 100%;
}