@import '../../assets/CollorPallet.module.scss';

.textField {
    margin: 10px !important;
    width: 350px
}


.buttonSave {
    background-color: $primary-color !important;
    color: white !important;
    margin: 10px !important;
    width: 30%;
}

.buttonSave_sx {
    background-color: $primary-color !important;
    color: white !important;
    margin-top: 10px !important;
}

.buttonCancel {
    background-color: $error-color !important;
    color: white !important;
    margin: 10px !important;
    width: 30%;
}

.buttonCancel_sx {
    background-color: $error-color !important;
    color: white !important;
    margin-top: 10px !important;
}

.buttons {
    justify-content: center;
    display: flex;
    margin: 10px;
}

.buttons_sx {
    margin: 10px;
}

.closeIcon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 20px;
}