$primary-color:#519E00;
$secondary-color: #B4C408;
$dashboard-color: #570091;
$dashboard-color-light: #9700fb;

$error-color: #E26868;
$success-color: #12A152;

$black:#000;
$white:"#fff";
$light-gray:#fcfcfc;
$lighter-gray: #ecf0f3ce;