@import '../../assets/CollorPallet.module.scss';

.answerGrid {
    align-items: center;
    text-align: center;
}

.deleteIcon {
    font-size: 25px;
    margin: 10px;
    cursor: pointer;
}

.questionForAnswers {
    background-color: $light-gray;
    padding: 15px;
    margin-left: 15px;
}

.questionPaper {
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    background: transparent;
}

.questionnairesBtn {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.questions {
    margin-bottom: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    text-align: center !important;
}

.question {
    padding: 15px;
    margin: 15px 0px;
    width: 99%;
}

.btnIcon {
    font-size: 25px;
    margin: 10px;
    cursor: pointer;
}

.questionnaireContainer {
    height: calc(100vh - 102px);
    overflow-y: auto;
    margin-top: 9px
}

.questionnaireContainerPaper {
    padding: 25px;
    width: 99%;
}

.questionnaireMenuPaper {
    padding: 10px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
}