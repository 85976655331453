@import '../../assets/CollorPallet.module.scss';

.root {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #000;
  background-image: radial-gradient(circle, $dashboard-color-light, $dashboard-color);
}

.textField {
  margin: 5px !important;
  height: 50px;
  width: 300px;
  color: #FFF !important;
  background: transparent !important;
}

.errorText {
  font-size: 17px !important;
  margin: 15px !important;
  color: red !important;
}

.button {
  height: 50px;
  width: 300px;
  margin-top: 30px !important;
}

.form {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  width: 450px;
}

.text {
  color: #FFF;
  text-decoration: inherit;
}

.bottomText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
  margin-top: 10;
}
